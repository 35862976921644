.to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
}

.to-top-button.show {
    opacity: 1;
}

.to-top-button.hide {
    opacity: 0;
    pointer-events: none;
}

/* Add your additional styling here */

.to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1B9CBE;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.to-top-button:hover {
    background-color: #FDB713;
}

.arrow {
    position: relative;
    top: -1px; /* Adjust as needed for centering */
}

